@import '@aurora/shared-client/styles/mixins/_panel.pcss';
@import '@aurora/shared-client/styles/mixins/_typography.pcss';

.lia-card-wrap {
  container-type: inline-size;
  container-name: card-wrap;
}

@container card-wrap (max-width: 601px) {
  .lia-message {
    grid-template-columns: auto;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
      'image'
      'body'
      'footer';

    .lia-message-img-wrap {
      height: 200px;
      width: 100%;
    }
  }

  .lia-body-wrap.lia-body-wrap {
    padding: 20px 15px;
  }

  .lia-subtitle-wrap {
    margin-bottom: 10px;
  }

  .lia-subject {
    @include heading-defaults(h5);
  }

  .lia-message-footer {
    padding: 15px;
  }

  .lia-is-centered.lia-is-centered {
    text-align: center;

    .lia-solved-badge {
      margin-top: 0;
    }

    .lia-byline {
      justify-content: center;
      font-size: var(--lia-bs-font-size-sm);
    }

    .lia-sub-heading {
      justify-content: center;

      .lia-subtitle-wrap {
        flex-direction: column;
        justify-content: center;
        margin-right: 0;
      }

      .lia-subject-wrap {
        flex-direction: column;
        align-items: center;
      }
    }

    .lia-tag-list {
      justify-content: center;
    }

    &.lia-message-footer {
      justify-content: center;
    }
  }
}

@container card-wrap (min-width: 600px) {
  .lia-message {
    grid-template-columns: fit-content(300px) 1fr;
    grid-template-rows: 1fr auto;
    grid-template-areas:
      'image body'
      'image footer';
    min-height: 100px;

    .lia-message-img-wrap {
      width: 100%;
      height: 100%;
      min-width: 300px;
    }
  }

  .lia-subtitle-wrap {
    margin-bottom: 25px;
  }

  .lia-body-wrap.lia-body-wrap {
    padding: 25px;
  }

  .lia-message-footer {
    padding: 15px 25px;
  }

  .lia-subject {
    @include heading-defaults(h3);
  }

  .lia-body-content {
    font-size: var(--lia-bs-font-size-base);

    &.lia-has-clamp {
      -webkit-line-clamp: var(--lia-mvc-clamp-landscape);
    }
  }
}

@container card-wrap (min-width: 800px) {
  .lia-subject {
    @include heading-defaults(h2);
  }

  .lia-body-content {
    margin-bottom: 15px;
    font-size: var(--lia-bs-font-size-lg);
  }
}

.lia-message {
  display: grid;
  @include panel-defaults();
  overflow: hidden;
  text-align: left;

  .lia-message-img-wrap {
    grid-area: image;
    display: block;
    position: relative;
  }

  .lia-message-img {
    position: absolute;
    inset: 0 0 0 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50%;

    &.lia-is-fallback {
      background-color: var(--lia-bs-gray-700);
    }
  }

  .lia-sub-heading,
  .lia-subtitle-wrap,
  .lia-subject-wrap {
    display: flex;
  }

  .lia-subject-wrap {
    gap: 5px;
    align-items: flex-start;
    justify-content: space-between;
  }

  .lia-body-wrap.lia-body-wrap {
    grid-area: body;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .lia-sub-heading {
      justify-content: space-between;
      flex-direction: column;
      width: 100%;
    }
  }

  .lia-message-footer {
    grid-area: footer;
    display: flex;
    gap: 15px;
    margin-top: 0;
    border-top: 1px solid var(--lia-panel-border-color);
  }

  &:has(.lia-message-img-wrap:hover) .lia-subject-link {
    text-decoration: underline;
  }
}

.lia-subtitle {
  display: block;
  line-height: var(--lia-bs-line-height-base);
  color: var(--lia-bs-gray-800);
  letter-spacing: 2px;
}

.lia-subject {
  margin-bottom: 0;

  &:hover {
    text-decoration: underline;
  }
}

.lia-subject-link {
  color: var(--lia-bs-body-color);

  &:hover,
  &:focus {
    color: var(--lia-bs-body-color);
    text-decoration: none;
  }
}

.lia-body-content.lia-has-clamp {
  -webkit-line-clamp: var(--lia-mvc-clamp-portrait);
}

.lia-auto-space {
  width: 100%;
  margin: -10px 0 auto;
}

.lia-byline {
  display: flex;
  align-items: center;

  .lia-byline-text {
    display: inline-block;
    z-index: 2;
  }

  .lia-byline-item {
    word-break: break-word;
    color: var(--lia-bs-gray-700);
    font-size: var(--lia-bs-font-size-sm);
  }

  &-md {
    font-size: var(--lia-bs-font-size-sm);
  }
}

.lia-card-item {
  &,
  &:hover,
  &:focus {
    word-break: break-word;
    color: var(--lia-bs-gray-700);
  }
  &:not(a) {
    cursor: default;
  }
}

.lia-timestamp {
  font-size: var(--lia-bs-font-size-sm);
  color: var(--lia-bs-gray-700);
  font-weight: var(--lia-bs-font-weight-base);
  z-index: 2;

  &:hover,
  &:focus {
    color: var(--lia-bs-gray-700);
  }
}

.lia-solved-badge {
  max-height: 22px;
  margin-top: 2px;
}

.lia-tag-list {
  z-index: 2;
}

.lia-rank {
  margin: 0 var(--lia-divider-mr) 0 5px;
  display: inline-flex;
  align-items: center;
}

.lia-dot:after {
  content: '';
  display: inline-block;
  background-color: var(--lia-divider-bg-color);
  margin-left: var(--lia-divider-ml);
  height: var(--lia-divider-size);
  width: var(--lia-divider-size);
  border-radius: var(--lia-divider-border-radius);
  vertical-align: middle;
}
